import React from 'react';

import SEO from '../components/seo';
import ContentContainer from '../components/content-container';

const NotFoundPage = () => (
  <ContentContainer>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>This page doesn&#39;t exist... sorry!</p>
  </ContentContainer>
);

export default NotFoundPage;
